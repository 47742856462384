<template>
    <div class="appointment_status_popup_wrp">
        <div class="appointment_status_popup_modal">
            <div class="appointment_status_popup_body">
                <template v-if="appointmentStatusForFLAG == 'Apporve'">
                    <h3 class="appointment_status_popup_heading">Are you sure want to confirm this appointment?</h3>
                </template>
                <template v-else-if="appointmentStatusForFLAG == 'Cancel'">
                    <h3 class="appointment_status_popup_heading">Are you sure you want to cancel the appointment? After
                        cancellation, the LBIL member will receive an autoreply requesting them to book a new
                        appointment with you.</h3>
                </template>
                <template v-else-if="appointmentStatusForFLAG == 'Close'">
                    <h3 class="appointment_status_popup_heading">Are you sure want to close this appointment?</h3>
                </template>
                <div class="appointment_status_popup_btn_wrp">
                    <button class="btn btn-danger" @click="appointmentStatusPopupDecline">Decline</button>
                    <button class="btn btn-success ml-3" @click="appointmentStatusUpdatedFun">Yes, I want</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/*eslint-disable */
const $ = window.jQuery = require('jquery');
import toast from '@/services/toast';
import {bus} from '@/main';  
export default {
  name: 'appointment-status-change-popup',
  components: {},
  props: ['appointmentStatusForData', 'appointmentStatusForFLAG'],
  data () {
    return {
    }
  },
  computed: {

  },
  mounted () {
  },
  methods: {
    appointmentStatusUpdatedFun(){
      var appointment_status;
      var status_message;
      if(this.appointmentStatusForFLAG == 'Apporve'){
        appointment_status = 'appointment_confirmed'
        status_message =  'Appointment has been confirmed'
        var data = {
          is_approved: true,
        }
      }else if(this.appointmentStatusForFLAG == 'Cancel'){
        appointment_status = 'appointment_cancelled'
        status_message = 'Appointment has been cancelled'
        var data = {
          is_cancelled: true,
        }
      }else if(this.appointmentStatusForFLAG == 'Close'){
        appointment_status = 'appointment_closed',
        status_message = 'Appointment has been closed'
        var data = {
          is_closed: true,
        }
      }
      this.$store.dispatch('updateAppointmentDetailsMethod', {data:{id:this.appointmentStatusForData.id,data:data}}).then((res)=>{
        this.$store.dispatch('getconsultPatientDetailByIdAction', {id:this.appointmentStatusForData.id}).then((res) => {
          // let notificationPayload = { 
          //   type:appointment_status,
          //   data_id:res.data.data.data.id,
          //   message:status_message
          // }
          // this.$store.dispatch('postNotificationFunc', notificationPayload)
          toast.success(res.data.msg || "Request processed successfully");
          this.$store.dispatch('getPatientsListAction')
          this.appointmentStatusPopupDecline();
        })
        this.appointmentStatusPopupDecline();
      }).catch((err)=>{
        toast.error(err.data.msg);
      })
    },
    appointmentStatusPopupDecline(){
      bus.$emit('appointmentStatusPopupBus', false);
      document.body.classList.remove('body_right_scroll_remove');
    }
  }
}
</script>
<style lang="scss" scoped>
.appointment_status_popup_wrp{
    position: fixed;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.92);
    left: 0;
    right: 0;
    z-index: 1000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .appointment_status_popup_modal{
        background: #000;
        max-width: 720px;
        margin: 0px auto;
        border-radius: 5px;
        padding:25px 15px;
        font-family: 'MyriadProRegular','Lato',sans-serif;
        color: #ffffff;
        -webkit-box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
        box-shadow: 3px 3px 3px rgba(255, 255, 255, 0.04), -3px -3px 3px rgba(255, 255, 255, 0.04);
    }
    .appointment_status_popup_heading {
        font-size: 20px;
        color: #ffffff;
        font-family: 'MyriadProRegular','Lato',sans-serif;
        text-align: center;
        margin: 0 0 30px;
        line-height:1.3;
      @media only screen and (max-width:767px){
        font-size:16px;
      }
    }
    .appointment_status_popup_btn_wrp{
        text-align:center;
        .btn {
            border: none;
            min-width: 105px;
        }
    }
}
</style>